@import './variables';

// Core variables and mixins
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/modals';

@import '~bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/buttons';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/button-groups';

body,
html {
  height: 100%;
  background-color: $color-bg--primary;
  background-image: radial-gradient(
    darken($color-bg--primary, 2),
    $color-bg--primary
  );
  color: $color-text--primary;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;

  &.dark-mode {
    background-color: $color-bg--secondary;
    background-image: radial-gradient(
      darken($color-bg--secondary, 2),
      $color-bg--secondary
    );
    color: $color-text--secondary;
  }
}

// body {
//   will-change: scroll-position;
// }

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 0.75em;
}

h5 {
  font-size: 0.5em;
}

h6 {
  font-size: 0.25em;
}

* {
  box-sizing: inherit;

  .is-desktop & {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
      height: 8px;

      &-track {
        padding: 2px;
        background-color: rgba(#000, 0.15);
      }

      &-thumb {
        border-radius: 5px;
        cursor: pointer;
        @apply bg-gray-300;

        &::before {
          content: '';
          width: 2px;
          height: 100%;
          display: block;
          background-color: #636363;
        }
      }
    }
  }
}

a {
  //   font-weight: bold;
  text-decoration: none;
  color: #0054ab;

  &:hover,
  &:active,
  &.router-link-exact-active {
    color: #4299e1;
    text-decoration: underline;
  }

}

* {
  &.modal-open .modal::-webkit-scrollbar,
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &.modal-open .modal::-webkit-scrollbar:hover,
  &::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }

  

  &.modal-open .modal::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-track-piece {
    background-color: #efefef;
  }

  &.modal-open .modal::-webkit-scrollbar-track-piece:hover,
  &::-webkit-scrollbar-track-piece:hover {
    background-color: #d0d0d0;
  }

  &.modal-open .modal::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:vertical {
    background-color: #838383;
  }

  &.modal-open .modal::-webkit-scrollbar-thumb:vertical:hover,
  &::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #696969;
  }
}

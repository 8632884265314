
.payment-check {
  text-align: center;
  // max-width: 300px;
  // max-height: 300px;
  // min-width: 600px;

  // width: 525px;

  .btn {
    // margin-left: auto;
    margin-top: 2rem;
  }

  &__addresses {
    display: flex;
    margin-bottom: 2rem;
  }

  .addr {
    flex: 1;

    h4 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;

      .small {
        font-weight: bold;
        font-style: italic;
        font-size: 0.8rem;
      }
    }

    p {
      font-size: 1.2rem;
    }

    &__small {
      h4 {
        font-size: 0.9rem;
        font-weight: 800;
        display: flex;
        align-items: baseline;
        margin-bottom: 0;

        > * {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
      display: flex;
      flex-flow: column;
      align-items: center;
      font-style: italic;
      font-size: 0.7rem;

      p {
        line-height: normal;
        margin-bottom: 5px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}

.addr {
  max-width: 100%;
  > * {
    margin: 0;
    line-height: normal;
  }
}

.bottom-align {
  align-items: flex-end;
}

.text-center {
  margin-bottom: 1rem;
  font-weight: bold;
  font-style: italic;
}

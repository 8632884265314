
.amount-date-row {
  @media screen and (max-width: 1440px) {
    display: flex;
    flex-direction: column;
  }
}

::v-deep .dialog-class {
  @media screen and (max-width: 1440px) {
    height: 850px;
  }

  min-width: 490px;
}

::v-deep .update-dialog {
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 500px;
    max-height: 65vh;
    margin: unset;
    margin-left: auto;
    margin-right: auto;
  }

  // need to set !important up to 767
  // in order to address element-ui margin style
  // directly on the element.
  @media screen and (max-width: 767px) {
    margin: 0 !important;
  }
}

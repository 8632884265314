@import './variables';
@import './utils/utils';
@import './typography';
@import './base';
@import './stripe';
@import './buttons';
@import './ticks';
@import './overrides';

#ada-button-frame {
  right: auto;
  left: 0 !important;
}


#ada-chat-frame {
  right: auto;
  left: 24px !important;
}

#ada-entry div:first-child {
  right: auto;
  left: 0;
  transform: rotateY(180deg);
}

#ada-intro-frame {
  left: 0px !important;
  bottom: 60px !important;
}


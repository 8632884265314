
.view-new {
  background-color: #26a97b;
  color: #fff;
  width: 100%;
  padding: 9px 10px 11px 10px;
  border-radius: 4px;
  font: inherit;
}
.stateFull {
  margin-right: 120px;
}
.content-loading {
  margin-top: -120px;
  margin-bottom: -100px;
}
::v-deep .dialog-class {
  margin-bottom: auto;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.colMargin {
  margin-top: 1.5rem;
}
.titleColor {
  color: rgb(103, 156, 187);
}

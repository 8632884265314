
button {
  background-color: hsla(198, 85%, 44%, 0);
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  border: 2px solid rgba(0, 169, 222, 0.5);
  transition: all 400ms cubic-bezier(0, 1.21, 0.64, 1.41);
  padding: 0.5rem 2.5rem;
  position: relative;
  transform: scale(1);
  backface-visibility: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    backface-visibility: hidden;

    background-color: #004c9d;
    transition: all 100ms cubic-bezier(0.41, 0.03, 0.25, 1);
  }

  &:hover,
  &:active,
  &:focus {
    transform: scale(1.2);
    transform-style: flat;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;

    padding: 0.5rem 2.5rem;
    text-shadow: 1px 2px 1px hsla(0, 0%, 0%, 0.77);

    &:before {
      height: 100%;
      opacity: 0.1;
      background-color: #004c9d;
      border: 1px solid #004c9d;
    }
  }
}

span {
  position: relative;
}
.earthquakeButton {
  background-color: hsla(198, 85%, 44%, 0);
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  border: 2px solid rgb(255, 97, 76);
  transition: all 400ms cubic-bezier(0, 1.21, 0.64, 1.41);
  padding: 0.5rem 2.5rem;
  text-shadow: 1px 2px 1px hsla(0, 0%, 0%, 0.77);
  position: relative;
  transform: scale(1);
  backface-visibility: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    backface-visibility: hidden;

    background-color: rgb(255, 97, 76);
    transition: all 100ms cubic-bezier(0.41, 0.03, 0.25, 1);
  }

  &:hover,
  &:active,
  &:focus {
    transform: scale(1.2);
    transform-style: flat;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;

    padding: 0.5rem 2.5rem;
    text-shadow: 1px 2px 1px hsla(0, 0%, 0%, 0.77);

    &:before {
      height: 100%;
      background-color: rgb(255, 97, 76);
      border: 1px solid rgb(255, 97, 76);
      filter: tint(75%);
    }
  }
}


table {
  width: 100%;

  th {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }

  tr + tr {
    background-color: darken(#fff, 1);

    border-top: 1px solid darken(#fff, 15);

    &:nth-child(even) {
      background-color: darken(#fff, 3);
    }
  }

  td,
  th {
    padding: 5px 8px;
  }
}
.quick-align {
  text-align: right;
}


.policy-holder-summary {
  position: relative;
}

.map-container {
  margin: 0 !important;
  box-shadow: 0 0 15px 0 rgba(#000, 0.5);
  border: 2px solid #fff;
  height: 160px;

  ::v-deep .map-display {
    height: 100%;
    padding: 0;
  }
}

.content-wrapper {
  display: flex;
  flex-flow: column;

  > * {
    display: flex;
    flex-flow: column;
    margin-bottom: 1.5rem;

    h2 {
      color: lighten(#000, 5);
      font-size: 1.7rem;
      margin-bottom: 0;
      line-height: 2.4rem;
    }
  }

  .address {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 1rem 0 1.5rem;
  }

  .info-items {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  .info-item {
    display: flex;
    flex-flow: column;
    margin-bottom: 0.5rem;
    white-space: nowrap;

    &.total {
      font-size: 1.5em;
    }

    @media screen and (min-width: 800px) {
      flex-flow: row;
      align-items: baseline;
      border-bottom: 1px solid #cecece;
      line-height: 1.5rem;

      &.total {
        border: 0;
        margin-top: 2rem;

        > * {
          &:nth-child(2) {
            color: #3f51b5;
            font-size: 1.5rem;
          }
        }
      }

      > * {
        &:first-child {
          font-weight: bold;
          margin-right: 1rem;
        }

        &:nth-child(2) {
          margin-left: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1.2em;
          font-weight: bold;
          color: #3f51b5;
        }
      }
    }
  }
}

pre {
  font-size: 0.7rem;
}
.buttonOverride {
  color: rgb(255, 97, 76);
  background: white;
  border: 1px solid rgb(255, 97, 76);
  text-shadow: none;
  &:hover,
  &:active,
  &:focus {
    filter: tint(75%);
    color: white;
  }
}


.policy-wizard {
  transition: background 500ms ease;

  &.is-agent {
    background-color: #0c5655;
    .theme-earthquake & {
      background-color: #ff614c;
    }
  }

  &-content {
    margin: 0 auto;

    .ie &,
    .k-ie11 & {
      display: block;
      width: 100%;
    }

    transition: transform 300ms ease;
    .loading & {
      transform: scale(0.9);
      opacity: 0.5;
    }

    ::v-deep {
      .header-title {
        font-size: 1.3rem;
        text-align: center;
        text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.62);

        p {
          margin: 1rem 0;
        }

        // This centers the logo
        img {
          margin: 0 auto 5rem;
        }
      }

      h1 {
        font-size: 3rem;
      }

      h2 {
        font-size: 2.5rem;
      }

      h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        line-height: normal;
      }
    }
  }
  &-component {
    margin-bottom: 300px;

    transition: opacity 500ms ease, filter 500ms ease;

    .loading & {
      opacity: 0.5;
    }

    ::v-deep a {
      color: #94d0ff;
      font-weight: 600;
      &:hover,
      &:active {
        color: #03f4f4;
      }
    }
  }

  ::v-deep .jumbo-button {
    font-size: 2rem;
  }

  scrollbar-width: thin;

  .is-desktop & {
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 4px;
      height: 4px;

      &-track {
        padding: 2px;
        background-color: rgba(#000, 0.15);
      }

      &-thumb {
        background-color: #444444;
        border-radius: 6px;

        &::before {
          content: "";
          width: 2px;
          height: 100%;
          display: block;
          background-color: #636363;
        }
      }
    }
  }
}

.sidebar {
  display: none;
  background-color: darken(#fff, 1);
  color: lighten(#000, 5);
  max-width: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 3px solid rgba(255, 255, 255, 0);

  position: absolute;
  right: 0;
  height: 100vh;
  box-shadow: 0 0 6px rgba(#000, 0.5);
  transform: translateX(100%);
  width: 0;
  transition: transform 200ms ease, width 200ms ease;

  @media screen and (min-width: 1024px) {
    position: relative;
    right: auto;
    width: auto;
    transform: none;
  }

  > * {
    padding: 1rem;
  }
}

.floating-status {
  margin: auto;
}

::v-deep img.logo {
  height: 50px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.6));
  position: relative;
  top: 2rem;
}

::v-deep .header-title {
  padding: 0 2rem;
}

.debugger {
  position: absolute;
  opacity: 0.01;
  transition: opacity 100ms ease;
  top: 0;
  left: 0;
  margin: 5px;
  padding: 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  display: grid;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
  font-size: 0.7rem;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.7);

  ::v-deep * {
    font-size: 1em !important;
  }

  &:hover {
    opacity: 1;
  }

  .stepper {
    display: flex;
    flex-flow: column;

    color: #000;
  }
}

.spinner {
  transition: all 2s ease;
  transform: perspective(1000px) translate3d(0, 0, 25px);
  opacity: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .loading & {
    opacity: 1;
    transform: perspective(1000px) translate3d(0, 0, 0);
    background-color: hsla(0, 0%, 0%, 0.41);
  }

  &__inner {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

::v-deep .popup {
  @media screen and (max-width: 767px) {
    .el-card {
      display: flex;
      flex-flow: column;

      &__header {
        box-shadow: 0px 2px 9px hsla(0, 0%, 0%, 0.55);
        position: relative;
        z-index: 1000;
      }

      &__body {
        flex: 1;
        overflow: auto;
      }
    }
  }
}
.auto-bind {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottie {
  max-width: 300px;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
}
.error-container {
  margin-top: 17rem;
  text-align: center;
}
// js styles
.jswizard {
  display: flex;
  background: linear-gradient(-30deg, #f9e7c6, #ffdaed 60%);
  color: #fff;
  transition: background 500ms ease;
  &-wrap {
    flex: 1;
    display: flex;
    position: relative;
    &__inner {
      position: relative;
      flex: 1;
      display: flex;
      overflow-x: hidden;
      overflow-y: scroll;
      border-right: 3px solid rgba(255, 255, 255, 0);
      background: linear-gradient(-30deg, #f9e7c6, #ffdaed 60%);

      &::-webkit-scrollbar-thumb {
        background-color: rgba(#fff, 0.5) !important;
      }
      .loading & {
        pointer-events: none;
      }
    }
  }
  &-content {
    margin: 1rem auto auto;
    .ie &,
    .k-ie11 & {
      display: block;
      width: 100%;
    }
    transition: transform 300ms ease;
    .loading & {
      transform: scale(0.9);
      opacity: 0.5;
    }
    ::v-deep {
      .header-title {
        font-size: 1.3rem;
        text-align: center;
        text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.62);
        p {
          margin: 1rem 0;
        }
        img {
          margin: 0 auto 5rem;
        }
      }
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 2.5rem;
      }
      h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        line-height: normal;
      }
    }
  }
  &-component {
    margin-bottom: 300px;
    transition: opacity 500ms ease, filter 500ms ease;
    .loading & {
      opacity: 0.5;
    }
    ::v-deep a {
      color: #94d0ff;
      font-weight: 600;
      &:hover,
      &:active {
        color: #03f4f4;
      }
    }
  }
  ::v-deep .jumbo-button {
    font-size: 2rem;
  }
  scrollbar-width: thin;
  .is-desktop & {
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 4px;
      height: 4px;
      &-track {
        padding: 2px;
        background-color: rgba(#000, 0.15);
      }
      &-thumb {
        background-color: #444444;
        border-radius: 6px;
        &::before {
          content: "";
          width: 2px;
          height: 100%;
          display: block;
          background-color: #636363;
        }
      }
    }
  }
}
.loadingText {
  background-color: #00000070;
}


.flex-1 .status {
  font-size: 20px;
}

.content-wrap {
  min-width: 500px;
}
::v-deep .dialog-class {
  width: 100%;
  margin: 0 !important;

  @media screen and (min-width: 768px) {
    width: 50%;
    max-width: 480px;
    margin: auto !important;
  }
}

.margin-nudge {
  margin-top: 1px;
}

.disabledClass {
  color: #ff614c !important;
  border: 1px solid #ff614c !important;
}
.switch-spec {
  padding-top: 10px;
  @media screen and (max-width: 1550px) {
    display: flex;
    justify-content: center;
    padding-top: 0px;
    margin-bottom: 10px;
  }
}

@import '@/assets/sass/_variables.scss';
@import '~element-ui/packages/theme-chalk/src/mixins/_button';

$btn-text-shadow: 0 1px 1px rgba(#000, 0.5);

.btn {
  // user-select: ;
  //   pointer-events: none;
  white-space: nowrap;
  transition: background-color 100ms linear;

  @apply py-2 px-6 rounded;

  &:hover {
    transition-duration: 0;
  }

  &:focus {
    outline: none;
  }

  &:active {
    transform: translateY(1px);
  }

  &:active,
  &:focus {
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.22);
  }

  &-default {
    @apply bg-white text-gray-900;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      @apply bg-gray-300;
    }
  }

  &-primary {
    text-shadow: $btn-text-shadow;

    background-color: #004C9D;
    color:white;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      @apply bg-deepsea-full/75
    }
  }

  &-secondary {
    @apply bg-gray-200 text-black;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      @apply bg-gray-500;
    }
  }

  &-success {
    @apply bg-green-500 text-white;

    text-shadow: $btn-text-shadow;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      @apply bg-green-800;
    }
  }

  &-info {
    @apply bg-red-500 text-white;

    text-shadow: $btn-text-shadow;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      @apply bg-red-700;
    }
  }

  &-warning {
    @apply bg-orange-500 text-white;

    text-shadow: $btn-text-shadow;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      @apply bg-orange-800;
    }
  }

  &-error {
    text-shadow: $btn-text-shadow;

    @apply bg-red-500 text-white;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      @apply bg-red-800;
    }
  }

  &-link {
    @apply bg-transparent text-blue-800;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      @apply text-blue-900;
    }
  }

  &.disabled {
    pointer-events: none;
    text-shadow: none;

    @apply text-gray-200 cursor-not-allowed bg-gray-200;
  }
}


.el-button--primary {
  .theme-neptune & {
    @include button-variant(#fff, $color-neptune--hot-pink, $color-neptune--hot-pink);
  }

  .theme-commercial & {
    @include button-variant(#fff, #26a97b,#26a97b);
  }
  .theme-earthquake & {
    @include button-variant(rgb(255,97,76), white, white);
    border:1px solid rgb(255,97,76);
    font-weight: 600;
    &:hover {
      @include button-variant(white, rgb(255,97,76), rgb(255,97,76));
      filter: tint(75%);
    }
  }
}

.el-button--success {
  .theme-neptune & {
    @include button-variant(#fff, #4299e1,#4299e1);
  }

  .theme-earthquake & {
    @include button-variant(#fff, rgb(103,156,187),rgb(103,156,187))
  }
  .theme-commercial & {
    @include button-variant(#fff, #2693a9,#2693a9);
  }
}

.grid {
  display: grid;

  &-centered {
    // place-items: center center;
    
    > * {
      align-self: center;
      justify-self: center;
    }
  }
}

.grid-2-col {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  @media screen and (min-width: 1024px) {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
  }
}

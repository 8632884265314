
::v-deep h2 {
  font-size: 1.5rem !important;
  color: #2c5282;
  font-weight: bold;
  margin-bottom: 1rem;
}

::v-deep ol {
  @apply space-y-4;
  @apply pl-4;
  list-style: decimal;
}

.signature {
  &__wrapper {
    height: 45px;
  }
}

.toolbar {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.sign-button {
  font-size: 2.2rem;
}

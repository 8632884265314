
.drawing-pad {
  position: relative;
  border: 2px solid #ccc;
  height: 150px;
  margin-bottom: 0.5rem;
}

.signature-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

::v-deep .el-form-item {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  // justify-content: stretch;
}

::v-deep .el-form-item__label {
  line-height: normal;
  margin-bottom: 0;
  font-weight: bold;
}
.marginClass {
  margin-top: 20px;
}

.footer-class {
  position: absolute;
}

.elany-notice {
  padding-left: 27%;
  color: red;
  font-size: 12px;
  margin: -15px 5px;
  display: flex;
  p {
    margin-left: 20px;
  }
}
.notice-check {
  padding: 20px 5px 10px;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #c8cacc;
  background-color: #f3f4f6;
}
::v-deep .el-switch__label.is-active {
  color: #004c9d !important;
}


.step3 {
  min-width: 500px;
  max-width: 600px;

  .file-item {
    border-bottom: 1px solid rgba(#000, 0.3);
    background-color: darken(#fff, 5);
  }
}

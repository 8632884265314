
.buttonOverride {
  color: rgb(255, 97, 76);
  background: white;
  border: 1px solid rgb(255, 97, 76);
  text-shadow: none;
  &:hover,
  &:active,
  &:focus {
    filter: tint(75%);
    color: white;
  }
}


.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.5);
  z-index: 1000;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.title {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 3rem;

  p {
    margin: 1rem 0;
  }
}

.card {
  width: 100%;
  max-width: 800px;
  align-self: center;
}

.signature {
  &-container {
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }
  &-inner {
    padding: 1rem;
    background-color: #fff;
    font-size: 0.95rem;
    color: lighten(#000, 1);
    box-shadow: 0 0 5px rgba(#000, 0.5);
    margin: 3vh 0;
  }
  &-form {
    &-container {
      position: relative;
    }
  }
}


.header-title {
  text-align: center;
}

.logo {
  height: 150px;
  width: auto;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}


.map-display {
  position: relative;
  background-color: #ccc;
  min-width: 100px;
  height: 0;
  padding-bottom: 56.25%;
  border-width: 0;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}

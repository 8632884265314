/*
Write your variables here. All available variables can be
found in element-ui/packages/theme-chalk/src/common/var.scss.
For example, to overwrite the theme color:
*/
$--color-primary: #3C589E;
$--select-input-color: lighten(#000, 20);
$--input-border: 1px solid #a09494;
$--checkbox-input-border: lighten(#000, 18);

/* icon font path, required */
$--font-path: '~element-ui/lib/theme-chalk/fonts';

@import "~element-ui/packages/theme-chalk/src/index";

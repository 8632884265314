
section {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.email-max-width {
  max-width: 300px;
  width: 300px;
  overflow: auto;
}

.weight-normal {
  font-weight: normal;
}

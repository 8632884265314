
svg {
  overflow: visible;

  path {
    fill: white;
    stroke: black;

    &.state-path {
      fill: none;
      stroke: rgba(#000, 0.5);
      transform: translateY(1px);
    }

    &.active {
      fill: #6aafaa;
      transition: fill 1s ease;

      &:hover {
        fill: lighten(#6aafaa, 10);
        transition-duration: 0ms;
      }

      .theme-commercial & {
        fill: #6aafaa;

        &:hover {
          fill: darken(#6aafaa, 10);
        }
      }
      .theme-earthquake & {
        fill: rgb(255, 136, 121);
        &:hover {
          fill: rgb(255, 192, 184);
        }
      }
    }

    &.highlight {
      fill: yellow;
      transition-duration: 0ms;
    }
  }
}


.sig-graph {
  width: 100%;
  height: 100%;
  position: relative;
}

.font-signature {
  font-family: "Alex Brush", Tahoma;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

svg {
  user-select: none;
}

.font-render {
  font-family: "Alex Brush", Tahoma;
  opacity: 0;
  user-select: none;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
}


.item {
  margin-bottom: 3rem;

  > * {
    display: flex;
    flex-flow: column;
    margin-bottom: 0.9rem;

    label {
      font-size: 1.2rem;
    }
  }
}
::v-deep .dialog-class {
  margin-bottom: auto;
}
.el-carousel__item h3 {
  // background-color: rgba(22, 171, 226, 0.7);
  font-size: 18px;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  // background-color: rgba(22, 171, 226, 0.9);
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: rgba(67, 174, 138, 0.9);
}

.bold-title {
  font-weight: bold;
}
.remove-bold {
  font-weight: normal;
}
.errorLabel {
  color: red;
}
.sub-header {
  font-weight: bold;
  margin-bottom: 15px;
}


.nav {
  &-footer {
    a {
      cursor: pointer;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@import '@/assets/sass/_variables.scss';

.tick {
  border-radius: 2em;
  border-color: rgba(#fff, 0.5);
  text-shadow: 0 1px 0 rgba(#000, 0.7);
  border-width: 2px;
  position: relative;
  top: -0.5em;

  @apply px-2 py-px text-xs;

  &.primary {
    @apply bg-red-600 text-white;
  }
}


iframe {
  pointer-events: none;
}
.error__page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  height: 100%;
  color: antiquewhite;
  @apply bg-lightair-light;
}
#main-bg {
  background: url("/static/images/backgrounds/updated_waves.webp") repeat;
  background-size: 100% 100%;
}
.button-height {
  height: 40px;
  margin-top: 80px;
  margin-left: 150px;
  margin-right: 150px;
}
.middle-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.left {
  background-color: #16abe2;
  color: antiquewhite;
  &:hover,
  &:focus {
    color: antiquewhite;
    background-color: #16abe2;
    filter: brightness(110%);
  }
}
.right {
  background-color: #ec1f80;
  color: antiquewhite;
  &:hover,
  &:focus {
    color: antiquewhite;
    background-color: #ec1f80;
    filter: brightness(110%);
  }
}


@import "@/assets/sass/_variables.scss";

.header {
  @media print {
    display: none;
  }
  position: fixed;
  height: $header-height;
  background-color: #f5f7fa;
  z-index: 1000;
  display: flex;
  grid-area: topnav;
  padding: 4px;
  top: 0;
  left: 0;
  width: 100%;
  user-select: none;
  transition: padding 200ms ease;

  .sidenav-hidden & {
    padding-left: 0;
  }

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    height: 100%;
    min-width: $header-height;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(#000, 0.25);
    }

    // &:focus {
    //    background-color: rgba(#000, 0.35);
    // }
  }

  &__logo {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 17px;
    color: #016ba5;
    flex-shrink: 0;
    &,
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &:hover {
      color: lighten(#016ba5, 20);
    }

    .theme-commercial & {
      color: #616161;

      &:hover {
        color: lighten(#4bbd95, 1);
      }
    }

    > * {
      font-size: inherit;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    img {
      height: 100%;
      padding: 0 4px;
      width: auto;
    }
  }

  &__group {
    display: flex;
    align-items: center;

    > * {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}

.menu-container {
  a {
    cursor: pointer;
    margin-bottom: 0.6rem;
  }
}

button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: none !important;
}

.spacer {
  flex: 1;
}

.avatar {
  ::v-deep img {
    max-height: 35px;
  }

  .theme-commercial & {
    border-color: #4bbd95;
  }

  .theme-neptune & {
    border-color: $color-neptune--hot-pink;
  }
}
.jsColorBorder {
  border-bottom-color: #ff614c !important;
}
.jsColorText {
  color: #016ba5;
}


.cancel-wizard {
  padding: 5px 15px;
  display: flex;
  flex-flow: column;
  max-width: 800px;
  white-space: initial;
  word-break: initial;

  .options {
    > * {
      width: 100%;
    }
  }
}

.reason {
  max-width: 400px;
}

.w-full {
  width: 100% !important;
}
.margin-nudge {
  margin-top: -1px;
}

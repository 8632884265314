
@import "@/assets/sass/_variables.scss";

.card {
  padding: 1rem;
  background-color: #fff;
  color: lighten(#000, 10);
  box-shadow: 0 0 3px rgba(#000, 0.5);
  margin-bottom: 2rem;
}

.policy-detail {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  scroll-margin-top: 50px;

  .can-gray {
    filter: saturate(1);
    transition: filter 500ms linear;
  }

  &.cancelled .can-gray {
    filter: saturate(0.4);

    ::v-deep .disable-in-cancel {
      pointer-events: none;
    }
  }

  &.pending-cancel .can-gray {
    filter: saturate(0.7);

    ::v-deep .disable-in-pending {
      pointer-events: none;
    }
  }
}

a {
  color: #0c5692;
  font-weight: bolder;
}

::v-deep .el-textarea {
  & .el-textarea__inner {
    @apply h-20;
  }
}
// .right-column {
//   > * {
//     margin-bottom: 1.5rem;
//   }
// }

.coverage-items {
  display: block;

  @supports not (display: -ms-grid) {
    display: grid;
    grid: auto / repeat(auto-fill, minmax(210px, 1fr));
  }
}

.tax-items {
  @supports not (display: -ms-grid) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    white-space: nowrap;
    grid-gap: 1rem;
  }

  .diff-item {
    .ie & {
      margin-right: 1rem;
    }
  }
}

.coverage-item {
  $circle-size: 35px;

  margin-right: 1.2rem;
  margin-bottom: 2rem;
  display: inline-grid;
  // grid: #{$circle-size} 1fr / #{$circle-size} 1fr;
  grid-template-columns: #{$circle-size} 1fr;
  grid-template-rows: #{$circle-size} 1fr;
  grid-gap: 10px;
  margin-top: 1rem;
  position: relative;

  .ie & {
    margin-right: 1rem;
    width: 220px;
    position: relative;
    display: flex;
    flex-flow: row wrap;
  }

  > strong {
    width: 100%;
    height: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #209686;
    color: #fff;
    box-shadow: 0 0 3px rgba(#000, 0.5);
    text-shadow: 0 0 3px rgba(#000, 0.75);
  }

  > label {
    align-self: center;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  &__content {
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    flex-flow: row;
    font-size: 0.85rem;

    > * {
      display: block;

      > * {
        display: block;

        &:not(:last-child) {
          margin-right: 1.9rem;
        }
      }
    }
  }
}

// .middle-content {
//   > * {
//     margin-bottom: 2rem;
//   }
// }

.top-header {
  &__image {
    .is-loading & {
      opacity: 0;
      transform: translateY(-20px);
    }

    &__inner {
      position: absolute;
      background-color: #122b4e;
      .theme-earthquake & {
        background-image: linear-gradient(30deg, #f9e7c6, 60%, #ffdaed);
      }
      color: #fff;
      width: 100%;
      height: 100%;

      .is-agent & {
        background-color: #0d5555;
        .theme-earthquake & {
          background-image: linear-gradient(30deg, #f9e7c6, 60%, #ffdaed);
        }
        .theme-neptune & {
          background-color: #016ba5;
        }
      }
    }
  }
}

.diff-item {
  align-items: flex-end;

  > * {
    &:nth-child(2) {
      font-size: 1.1rem;
    }
  }

  &.diff {
    label {
      color: red;
    }
  }
}

.diffed-text {
  color: red;
  font-weight: bold;
}

.timeline-item {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;

  &:hover,
  &.active {
    label {
      color: lighten(#000, 4);
    }
  }

  &.active {
    font-weight: 900;
    pointer-events: none;
  }

  label {
    cursor: inherit;
  }

  .items {
    display: flex;
    font-size: 0.7em;

    > div {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    .item {
      &-title {
        font-weight: bold;
      }
    }
  }
}

::v-deep .el-badge__content {
  box-shadow: -1px 2px 3px 0 rgba(0, 0, 0, 0.37);
}

.last-changes {
  overflow: hidden;
  width: 100%;

  > * {
    flex: 0 1 auto;
  }

  pre {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content-container {
  display: flex;
  flex: 1 0 auto;
  flex-flow: column;
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  transition: transform 450ms cubic-bezier(0, 0.84, 0.25, 1);

  opacity: 1;
  transform: translateY(0);

  .is-loading & {
    opacity: 0;
    transform: translateY(200px);
    pointer-events: none;
    position: absolute;
    height: 0;
    overflow: hidden;
  }

  > * {
    &:last-child {
      flex: 1;
    }

    &.policies {
      flex: 1 1 auto;
    }
  }

  &__title {
    display: flex;
    justify-content: flex-end;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 200;
    padding: 0 1em;
    text-align: center;

    @media screen and (min-width: 640px) {
      flex-flow: row;
      text-align: unset;
    }
    > .label {
      flex: 1 1 auto;
      position: relative;

      > p {
        visibility: hidden;
        position: absolute;
        font-size: 0.95rem;
        line-height: normal;
        width: 100%;

        .cancelled & {
          visibility: unset;
        }
      }
    }
    > .logo {
      width: 100%;
      max-width: 240px;
      .theme-earthquake & {
        max-width: 440px;
      }

      @media screen and (min-width: 640px) {
        flex: 0 1 auto;
        height: 50px;
        .theme-earthquake & {
          height: 150px;
        }
        width: auto;
        .theme-earthquake & {
          margin-bottom: -50px;
        }
      }

      > img {
        margin: auto;

        @media screen and (min-width: 640px) {
          height: 100%;
          width: auto;
        }
        filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.7));

        .theme-neptune & {
          filter: saturate(0) brightness(4);
        }
      }
    }
  }
}

.content-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  pointer-events: none;
}

.inline-block {
  display: inline-block;
}

.fs-dialog__wrapper {
  padding: 1rem;
}

::v-deep .fs-dialog {
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  // width: auto;
  margin: 0 auto;
  max-width: 1200px;

  .el-dialog__header {
    height: 50px;
    padding: 0;
    display: flex;
    z-index: 10;
    box-shadow: 0 0 5px 0 rgba(#000, 0.5);

    .el-dialog__headerbtn {
      position: relative;
      top: auto;
      right: auto;
      width: 50px;
      height: 50px;
      margin-left: auto;
    }
  }

  .el-dialog__body {
    flex: 1;
    overflow: hidden;
    padding: 0;
    position: relative;
    display: flex;

    > * {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      > * {
        flex: 1;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.cancelled {
  // pointer-events: none;
  user-select: none;
  // cursor: not-allowed;
}
::v-deep .dialog-class {
  margin-bottom: auto;
}
.payment-select-alert {
  font-size: 15px;
  margin-top: -18px;
  margin-bottom: 18px;
}
.payment-select-alert-active {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.transferModalBody {
  display: flex;
  justify-content: space-between;
  h2 {
    color: #0c5692;
  }
}
.to-card {
  border: 1px solid #209686;
  border-radius: 5px;
  width: 30%;
  padding: 10px 0px 10px 10px;
}
.from-card {
  border: 1px solid #209686;
  border-radius: 5px;
  width: 30%;
  padding: 10px 0px 10px 10px;
}
.trident-image {
  transform: rotate(90deg);
  width: 50px;
  height: 90px;
  margin: 0 auto;
}

.notifications__button {
  margin-left: 0px !important;
  margin-top: 10px;
  @media screen and (min-width: 1360px) {
    margin-left: 10px;
    margin-top: 0px;
  }
}

.overviewFull {
  width: 100% !important;
}
.overViewPartial {
  width: 700px;
}


.address {
  color: #4b5563;
}
.card-styles {
  border-bottom: 1px solid #ccc;
  padding: 2rem 1rem;
}
div {
  font-family: "Lato", sans-serif;
}

.list-button {
  float: right;
  font-size: 1.5rem;
  min-width: 240px;
  margin-top: -35px;
}

// location
.location__container {
  min-height: 6rem;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.location__container:not(:last-child) {
  border-bottom: 1px solid #d1d5db;
}

.location-info__container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.location-actions__container {
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 768px) {
    flex-direction: column;
  }
}

.location-action {
  background-color: #26a97b;
  border-color: #26a97b;
  border-radius: 0.25rem;
  // min-width: 240px;
  font-size: 1.2rem;
  color: #fff;
  padding: 0.5rem 1rem;
  // on the base
  margin-left: 1rem;
  min-width: 240px;
  @media screen and (min-width: 768px) {
    margin-left: 0;
  }
}

.location-action.secondary {
  background-color: transparent;
  color: #555;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
}

.location-action:not(:last-child) {
  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
}

.location-action:first-child {
  margin-left: 0;
}

.map__container {
  @media screen and (min-width: 768px) {
    max-width: 16rem;
  }
}

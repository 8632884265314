.StripeElement {
    padding: 10px 15px;
    border: 2px solid #000;
    border-radius: 5px;

    &--focus {
        border-color: #f21cbd;
    }

    &--invalid {
        // border-color: red;
    }

    &--complete {
        // border-color: green;
    }
   }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* hide number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.main-app {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-flow: column;
  // height: 100%

  .ie & {
    height: 100%;
  }
}


.collapser {
  .title {
    font-size: 1.25rem;
    white-space: nowrap;
    user-select: none;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding-right: 15px;
    height: 40px;
    cursor: pointer;

    transition: border 500ms ease;

    &:hover {
      background-color: #fbfafa;
      // border: 1px solid rgba(0, 0, 0, 0.16);

      [class*="el-icon"] {
        .theme-commercial & {
          color: #43ae8a;
        }
      }
    }

    .icon {
      font-size: 28px;
      line-height: normal;

      [class*="el-icon"] {
        .theme-commercial & {
          color: #828282;
        }
      }
    }

    .h4 {
      font-size: 1em;
    }
  }

  &.expanded {
    .title {
      .theme-earthquake & {
        border-color: #faca43;
      }
      .theme-commercial & {
        border-color: #43ae8a;
      }
      .theme-neptune & {
        border-color: #43ae8a;
      }
    }
  }
}

.content {
  position: relative;

  &-container {
    position: relative;
    transform: translateZ(0);
  }
}

[class*="el-icon"] {
  .theme-commercial & {
    color: #828282;
  }
}
.titleColor {
  color: rgb(103, 156, 187);
}

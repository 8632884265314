
.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: orangered;
  color: white;
  padding: 15px;
  width: 80%;
  margin-left: 10%;
  text-align: center;
  font-size: 20px;
  z-index: 1 !important;
  border-radius: 7px;
  .none {
    color: white;
  }
  span {
    cursor: pointer;
  }
}


.invoice {
  &-details {
    position: relative;
  }

  &-item {
    margin-bottom: 3rem;
  }

  &__body {
    display: flex;
  }
  &__field {
    display: flex;
    flex-flow: column;
    // justify-content: space-between;
    flex: 1 1 auto;
    align-items: baseline;
    // border-bottom: 1px dotted #000;
    margin-top: 0.6rem;
    font-size: 0.75rem;
    > * {
      &:last-child {
        font-weight: bold;
      }
    }
  }
  &__footer {
    display: flex;
  }
}

table {
  th,
  td {
    text-align: left;
    padding: 5px 8px;
  }
}
.invoice-button {
  color: #26a97b;
  width: 90px;
  cursor: pointer;
  &:hover {
    color: white;
    filter: brightness(110%);
  }
}
.inside {
  border-bottom: 2px solid #26a97b;
  &:hover {
    cursor: pointer;
    color: #26a97b;
    filter: brightness(100%);
  }
}


.step2 {
  width: 800px !important;
  height: 500px !important;
  max-width: 100%;
  max-height: 100%;
  position: relative;

  ::v-deep .popup > * {
    max-width: 800px;
  }

  .not-found {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slim {
    padding: 0;

    ::v-deep .signature-container {
      padding: 0;

      .signature-inner {
        margin: 0;
      }
    }
  }
}

@import './_grid';
@import './_transitions';

.fill {
  height: 100%;
}


.gpu {
  transform: translate3d(0,0,0);
  position: relative;
}

.spacer {
  flex:1 1 auto;
}

.abs-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.agent-details {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  > * {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }
}
.title-size {
  font-size: 20px;
}
.bold-title {
  font-weight: bold;
}
.marginBot {
  margin-bottom: 10px;
}


.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.5);
  z-index: 1000;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.title {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 3rem;

  p {
    margin: 1rem 0;
  }
}

.continue-button {
  margin: 3rem auto;
  align-self: center;
}

.card {
  width: 100%;
  max-width: 800px;
  align-self: center;
}

.signature {
  &-container {
    max-width: 1200px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin: 0 auto;
  }
}
.buttonOverride {
  color: rgb(255, 97, 76);
  background: white;
  border: 1px solid rgb(255, 97, 76);
  text-shadow: none;
  &:hover,
  &:active,
  &:focus {
    filter: tint(75%);
    color: white;
  }
}

.el-loading-spinner {
  svg {
    display: inline;
  }
}

.el-loading-mask {
  backdrop-filter: blur(10px);
}

.el-dialog__wrapper {
  display: flex;
}

.el-dialog-inline {
  width: auto !important;
  margin: auto !important;
  display: inline-block;

  &.contact-modal {
    min-width: 350px;

    .el-form {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(3, auto);
      column-gap: 20px;
    }
  }
}

.el-dialog.centered-dialog {
  padding: 0;
  position: relative;
  // width: 100%;
  // height: 100%;
  display: flex;
  flex-flow: column;
  width: auto;
  margin: auto;
  max-width: 1200px;

  &__wrapper {
    display: flex;
  }

  .el-dialog__header {
    height: 50px;
    padding: 0;
    display: flex;
    z-index: 10;
    box-shadow: 0 0 5px 0 rgba(#000, 0.5);

    .el-dialog__headerbtn {
      position: relative;
      top: auto;
      right: auto;
      width: 50px;
      height: 50px;
      margin-left: auto;
    }
  }

  .el-dialog__body {
    flex: 1;
    overflow: hidden;
    padding: 15px;
    position: relative;
    display: flex;
    word-break: normal;

    > * {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;

      > * {
        flex: 1;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.el-dialog__body {
  word-break: normal !important;
}

.popover-error {
  border: 1px solid red !important;

  .popper__arrow {
    border-right-color: red !important;
  }
}
.ellipsed {
  text-overflow: ellipsis;
}

*:focus,
button:focus {
  outline: none;
}

.border-white {
  .theme-commercial & {
    border-color: #285e61;
  }
}

a {
  .theme-commercial & {
    color: #0054A9;

    &:hover {
      color: lighten(#0054A9, 5);
      text-decoration: underline;
    }
  }
}

.ag-theme-alpine .ag-row-hover {
  background: #f3f4f6 !important;
}


.el-notification__content {
  text-align: left !important;
}

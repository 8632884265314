
table.uploaded {
  width: 100%;
  th {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }

  tr + tr {
    background-color: darken(#fff, 1);

    border-top: 1px solid darken(#fff, 15);

    &:nth-child(even) {
      background-color: darken(#fff, 3);
    }
  }

  td,
  th {
    padding: 5px 8px;
  }
  .col-style {
    width: 180px;
  }
  .col-style-right {
    width: 180px;
    text-align: right;
  }
  .col-container {
    width: 500px;
  }
  .indent {
    padding-left: 23px;
  }
}

.col-style-right {
  width: 180px;
  text-align: right;
}
.col-style-name {
  width: 200px;
  text-align: left;
}
.col-container {
  width: 500px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.hideDropdown {
  display: none;
}
.sign-button {
  margin-left: 10px;
  width: 70px;
}
.timestamp {
  font-size: 0.85em;
  color: rgb(146, 146, 146);
}
.center-icon {
  padding-left: 5%;
}
.file-upload-list-container {
  display: flex;
  width: 100%;
  padding: 5px 0px;
}
.snippetClosed {
  width: 300px !important;
}
.ec-snippet-open {
  width: 350px;
  color: red;
  font-family: "Lato";
}

.delete-margins {
  margin-left: -14px;
  margin-right: 7px;
  cursor: pointer;
  &:hover {
    color: crimson;
    filter: brightness(110%);
  }
}
::v-deep .dialog-body {
  height: 250px;
}
::v-deep .upload-dialog-body {
  height: 430px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide {
  &-left {
    &-enter {
      transform: translateX(-100vw);
      &-active {
        transition: all 500ms ease;
        
      }
      &-to {
        transform: translateX(0);
        // position: fixed;
      }
    } 
    
    &-leave {
      transform: translateX(0);
      &-active {

        transition: all 500ms ease;
      }
      &-to {
        transform: translateX(100vw);
        // position: fixed;
      }
    }
  }
  
  &-right {
    &-enter {
      transform: translateX(100vw);
      &-active {
        transition: all 500ms ease;
        
      }
      &-to {
        transform: translateX(0);
        position: fixed;
      }
    } 
    
    &-leave {
      transform: translateX(0);
      &-active,
      &-to {
        transition: all 500ms ease;
        transform: translateX(-100vw);
        position: fixed;
      }
    }
  }
}

.sidebar-thing {
  grid-area: side;
}

.pay-step {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;

  > * {
    margin-bottom: 2rem;
  }
}

.menu {
  display: flex;
  margin: 0.5rem 0 1.5rem;
  flex-flow: row wrap;
  justify-content: space-around;

  .menu-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
    padding: 1rem 2.5rem;
    white-space: nowrap;

    @media screen and (max-width: 1199px) {
      padding: 1rem 1rem;

      flex-flow: row;
      justify-content: center;
      align-items: center;
    }

    &:not(.active) {
      opacity: 1;

      &:hover {
        opacity: 1.4;
      }
    }

    .title {
      margin: 1rem 0;
      font-size: 1.1rem;
    }
  }
}

.content {
  width: 100%;
  max-width: 700px;
  padding: 1rem;

  ::v-deep .el-card {
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f8f8f8, #eaeaea);
    a {
      font-weight: normal;
      color: #0071bd;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: #177dc2;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }
}
.warning-text {
  color: red;
}
.logo-class {
  max-height: 450px;
  max-width: 450px;
}
.jsText {
  margin-top: -100px;
  background-color: #00000025;
  padding: 1rem;
  border-radius: 4px;
}
.js-background {
  background-color: #00000025;
  padding: 1rem;
  border-radius: 4px;
}
.buttonOverride {
  color: rgb(255, 97, 76);
  background: white;
  border: 1px solid rgb(255, 97, 76);
  text-shadow: none;
  &:hover,
  &:active,
  &:focus {
    filter: tint(75%);
    color: white;
  }
}
// http://localhost:8080/#/policyholder/new/VXoNtiZ8G9f%2bEy5XzCpPoM93ngiKqF7Fh8nyNErtbzRyLxYqiItSbXJT4LwKYPcbiXMQVIMbz52Q4SutV89mxxSB9EUa0wbREL0wdFkTbAYxZPMZ7GdohwpQ3UmXzzb8BcYSBaTy3VyvpO5bUclswvD6%2bI%2fKoA6SvMfBcYsLMSw%3d
